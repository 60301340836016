<template>
  <div class="advice">
    <el-table v-loading="dataListLoading" :data="tableData" size="small" border>
      <el-table-column
        width="220"
        show-overflow-tooltip
        prop="drug"
        label="用药"
      />
      <el-table-column width="100" prop="use_type" label="用药方法" />
      <el-table-column width="120" prop="standard" label="规格" />
      <el-table-column width="60" prop="dosage" label="计量" />
      <el-table-column width="90" prop="dosage_unit" label="计量单位" />
      <el-table-column width="70" prop="frequency" label="频度" />
      <el-table-column width="70" prop="num" label="用量" />
      <el-table-column width="90" prop="doctor_name" label="医生名" />
      <el-table-column show-overflow-tooltip prop="remark" label="备注" />
      <el-table-column width="70" prop="frequency" label="频度" />
      <el-table-column width="160" prop="start_time" label="开嘱时间" />
      <el-table-column width="160" prop="end_time" label="结束时间" />
    </el-table>
  </div>
</template>
<script>
import { ref, onMounted } from 'vue'
import { useRoute } from 'vue-router'
import service from '@/utils/request'
export default {
  name: 'advice',
  setup() {
    const tableData = ref([])
    const dataListLoading = ref(false)
    const route = useRoute()
    onMounted(() => {
      getDataList()
    })

    const getDataList = async () => {
      dataListLoading.value = true
      let res = await service.post('/api/patient/get_his_patient_advice_list', {
        patient_id: route.params.id,
      })
      dataListLoading.value = false
      if (res.code === 0) {
        tableData.value = res.data.list
      }
    }

    return {
      tableData,
      dataListLoading,
    }
  },
}
</script>

<style scoped lang="scss"></style>
